/* eslint-disable */
import React, { useRef, useEffect, useState, useCallback } from "react";
import * as Sentry from '@sentry/browser';
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import clsx from "clsx";

import "../App.css";
import settings from "../settings/settings";
import clearConnection from "../tests/clear-connection";

JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

JitsiMeetJS.init({
  useIPv6: false,
  disableSimulcast: false,
  enableWindowOnErrorHandler: true,
  disableThirdPartyRequests: true,
  disableRtx: false,
  // preferredCodec:
});

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "70%",
    margin: "0 auto",
  },
  spacer: {
    marginTop: "4rem",
  },
});

const getTestSet = (infra) => {
  return [
    {
      id: "server-connection",
      label: "Connexion au serveur vidéo",
      description: "Vérifie la connexion au serveur vidéo Klive",
    },
    {
      id: "join-conference",
      label: "Rejoindre une session vidéo",
      description:
        "Vérifie que votre navigateur peut rejoindre une session Klive",
      skip: (testResults, infra) =>
        testResults.find(({ id }) => id === `${infra}-server-connection`)
          ?.state === "KO",
    },
    {
      id: "audio-tracker",
      label: "Accès au micro",
      description:
        "Vérifie que votre navigateur a l'autorisation d'accéder à votre micro",
    },
    {
      id: "video-tracker",
      label: "Accès à la caméra",
      description:
        "Vérifie que votre navigateur a l'autorisation d'accéder à votre webcam",
    },

    {
      id: "stream-tracker",
      label: "Stream trackers",
      description:
        "Vérifie que votre navigateur peut envoyer un flux vidéo au serveurs Klive",
      skip: (testResults, infra) =>
        testResults.find(({ id }) => id === `${infra}-join-conference`)
          ?.state !== "OK",
    },
    {
      id: "udp-connection",
      label: `Test connexion UDP`,
      description:
        "Vérifie que votre réseau permet l'utilisation du protocol UDP (port 10000) pour le flux vidéo",
      skip: (testResults, infra) =>
        testResults.find(({ id }) => id === `${infra}-stream-tracker`)
          ?.state !== "OK",
    },
    {
      id: "tcp-connection",
      label: `Test connexion TCP ou UDP avec relais`,
      description:
        "Vérifie que votre réseau permet l'utilisation du protocol TCP (port 443) pour le flux vidéo",
      skip: (testResults, infra) =>
        testResults.find(({ id }) => id === `${infra}-udp-connection`)
          ?.state !== "KO",
    },
    {
      id: "region",
      label: `Détection de votre région`,
      description:
        "Nous cherchons la région disponible la plus proche de votre lieu actuelle",
      skip: (testResults, infra) =>
        infra !== "jaas" ||
        testResults.find(({ id }) => id === `${infra}-join-conference`)
          ?.state !== "OK",
    },
    {
      id: "bandwidth",
      label: "Test de bande-passante",
      description:
        "Vérifie que votre bande-passante est suffisante pour participer à une session Klive",
    },
    {
      id: "optimal-browser",
      label: "Navigateur optimal",
      description:
        "Vérifie que vous utilisez un navigateur totalement compatible avec la plateforme Klive",
    },
  ].map((t) => ({ ...t, infra }));
};

const ExecuteTests = ({ context, onFinish, infras, embed }) => {
  const classes = useStyle();
  const [infra, setInfra] = useState(infras[0]);
  const [tests, setTests] = useState(getTestSet(infra));
  const [testEnd, setTestEnd] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [currentTest, setCurrentTest] = useState(tests[0]);
  const [logs, setLogs] = useState([]);
  const status = useRef(true);
  const getNextTest = () => {
    const currentTestIdx = tests.findIndex(
      (t) => t.id === currentTest.id && t.infra === infra
    );
    const nextTestIdx = currentTestIdx + 1;
    return tests[nextTestIdx];
  };
  const failedTests = testResults.filter(({ state }) => state === "KO");

  const setStateTest = (test, state, details = "") => {
    setCurrentTest({ ...test, state, details });
  };

  const addLog = async (message, type = "log") => {
    const log = {
      time: new Date(),
      type,
      message,
    };
    console.log("\n", message, "\n");
    setLogs((preLogs) => [...preLogs, log]);
  };

  const runTest = async (test) => {
    // SKIP condition
    if (test.skip && test.skip(testResults, infra)) {
      addLog(`[${infra}] Test «${test.label}» skipped`);
      setStateTest(test, "SKIP");
      return true;
    }

    addLog(`[${infra}] Test «${test.label}» started ...`);
    setStateTest(test, "RUNNING");
    const cb = require(`../tests/${test.id}.js`).default;

    return await new Promise((_resolve, _reject) =>
      cb(_resolve, _reject, addLog, { ...context, infra })
    )
      .then(() => {
        setStateTest(test, "OK");
        addLog(`[${infra}] Test «${test.label}» ended`);

        return true;
      })
      .catch((error) => {
        addLog(
          `[${infra}] Test «${test.label}» error: ${String(error)}`,
          "error"
        );
        Sentry.captureException(error);
        setStateTest(test, "KO");

        return false;
      });
  };

  useEffect(() => {
    clearConnection();
    if (infra && infra !== infras[0]) {
      setTests((tests) => [...tests, ...getTestSet(infra)]);
    }
  }, [infra]);

  useEffect(() => {
    if (testEnd) {
      return onFinish(status.current, failedTests, logs);
    }
    if (currentTest && !currentTest.state) {
      (async () => {
        const result = await runTest(currentTest);
        status.current = status.current && result;
        if (settings.stopOnFailure && !result) return;

        const nextTest = getNextTest();
        if (nextTest) setTimeout(() => setCurrentTest(nextTest), 0);
        else {
          const infraIdx = infras.indexOf(infra);
          const nextInfra = infras[infraIdx + 1];

          if (nextInfra) setInfra(nextInfra);
          else setTestEnd(true);
        }
      })();
    }
  }, [currentTest, testEnd]);
  useEffect(() => {
    if (currentTest.state && currentTest.state !== "RUNNING") {
      const result = { ...currentTest, id: [infra, currentTest.id].join("-") };
      setTestResults((list) => [...list, result]);
    }
  }, [currentTest.state]);

  useEffect(() => {
    if (testResults.length > 0) {
      const nextTest = tests.find(({ state }) => !state);
      if (nextTest) setCurrentTest(nextTest);
    }
  }, [tests]);

  const stateLabel =
    { RUNNING: "EN COURS" }[currentTest.state] ?? currentTest.state;

  const isDev = process.env.NODE_ENV === "development";
  const reversedTestResults = [...testResults].reverse();

  const labelInfra =
    { aws: "Infrastructure actuelle", jaas: "Future infrastructure" }[infra] ??
    infra;

  return (
    <div className={classes.root}>
      {!embed && <div className={classes.spacer} />}

      {!embed && <h3>Tests en cours</h3>}

      {!embed && (
        <p>
          Les deux séries de tests en cours de réalisation ont pour objectif de
          s’assurer que votre expérience utilisateur en session visio soit
          optimale. Notre équipe support est destinataire des résultats du test.
          Ils reviendront vers vous pour vous indiquer la procédure à suivre, si
          ce dernier remonte des incohérences.
        </p>
      )}

      {!embed && <h5>{labelInfra}</h5>}

      <Card className={"containerTest"}>
        <CardContent style={{ padding: 4 }}>
          <div className={"containerTest-header"}>
            <div
              className={clsx([
                "containerTest-state",
                currentTest.state === "OK" && "containerTest-state-success",
                currentTest.state === "KO" && "containerTest-state-fail",
                ["RUNNING"].includes(currentTest.state) &&
                  "containerTest-state-running",
                ["SKIP"].includes(currentTest.state) &&
                  "containerTest-state-success",
              ])}
            >
              {stateLabel ?? "Not started"}
            </div>
            <div>{currentTest.label}</div>
          </div>
          <div>{currentTest.description}</div>
        </CardContent>
      </Card>

      <div className={classes.spacer} />

      {isDev &&
        reversedTestResults.map((test) => (
          <Card className={"containerTest"} key={test.id}>
            <CardContent style={{ padding: 4 }}>
              <div className={"containerTest-header"}>
                <div
                  className={clsx([
                    "containerTest-state",
                    test.state === "OK" && "containerTest-state-success",
                    test.state === "KO" && "containerTest-state-fail",
                    ["RUNNING"].includes(test.state) &&
                      "containerTest-state-running",
                    ["SKIP"].includes(test.state) &&
                      "containerTest-state-success",
                  ])}
                >
                  {test.state}
                </div>
                <div>{test.label}</div>
              </div>
              <div>{test.description}</div>
            </CardContent>
          </Card>
        ))}

      <div style={{ maxHeight: 150, overflow: "scroll", display: "none" }}>
        <video autoPlay id="cameraecho"></video>
      </div>
    </div>
  );
};

export default ExecuteTests;
