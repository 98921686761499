/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import platform from "platform";
import * as Sentry from "@sentry/browser";

import "./App.css";
import ExecuteTests from "./components/execute-tests";
import AskMail from "./components/ask-email";
import Summary from "./components/summary";
import Loading from "./components/loading";
import settings from "./settings/settings";

const sendResult = async (mail, status = false, logs) => {
  return fetch(`${settings.urlMeteor}/api/video-tests/send-result`, {
    method: "POST",
    mode: "no-cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    body: JSON.stringify({
      email: mail,
      logs: JSON.stringify(logs),
      status: status ? "OK" : "NOK",
    }),
  });
};

function App() {
  const [mail, setMail] = useState();
  const setEmail = (email) => {
    setMail(email);
    Sentry.setUser({ email });
  };
  const [summary, setSummary] = useState();
  const [context, setContext] = useState({});
  const iframeParent = useRef();
  const infras = useRef(settings.infras);
  const { roomName } = context;
  useEffect(() => {
    const receiveMessage = (event) => {
      // if (event.origin !== settings.urlMeteor) return;
      if (mail || !event.data?.email) return;
      // console.warn("RECEIVED", event);
      iframeParent.current = { window: event.source, url: event.origin };
      infras.current = event.data.infras ?? infras.current;
      setEmail(event.data.email);
    };
    window.addEventListener("message", receiveMessage, false);
  }, []);
  useEffect(() => {
    if (mail && !roomName) {
      fetch(`${settings.urlMeteor}/api/video-tests/get-config?email=${mail}`, {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }).then(
        async (result) => {
          if (result.ok) {
            setContext(await result.json());
          } else {
            throw new Error(`error in JWT fetch: ${result.status}`);
          }
        },
        async (reason) => {
          throw new Error(`error in JWT fetch: ${reason}`);
        }
      );
    }
  }, [mail, roomName]);
  const step = (() => {
    if (!mail) return "email";
    if (summary) return "summary";
    if (roomName) return "tests";
    return "loading";
  })();
  const onFinish = (endStatusResult, failedTests, logs) => {
    setSummary({ failedTests, logs });
    sendResult(mail, endStatusResult, logs);
    if (iframeParent.current) {
      iframeParent.current.window.postMessage({ result: endStatusResult }, "*");
    }
  };
  const isEmbed = !!iframeParent.current;

  return (
    <div className="App">
      {!isEmbed && (
        <img
          src={
            "https://www.kestio.com/wp-content/uploads/2021/01/kestio-logo.png"
          }
          width={"10%"}
          alt={"Kestio"}
          style={{ padding: 8 }}
        />
      )}

      {platform.name !== "Chrome" && !isEmbed && (
        <div
          style={{
            backgroundColor: "#FFAB00",
            color: "black",
            position: "fixed",
            top: 0,
            right: 0,
            width: "25vw",
            padding: 16,
            borderBottomLeftRadius: 8,
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            Attention : navigateur non supporté
          </div>
          <div>
            Malheureusement le navigateur que vous utilisez n'est pas pleinement
            supporté. Nous vous recommandons le navigateur Chrome pour une
            meilleure expérience.
          </div>
        </div>
      )}

      {!isEmbed && (
        <div>
          Vérifions que votre navigateur et votre réseau sont compatibles avec
          la technologie Klive
        </div>
      )}

      {step === "email" && <AskMail setMail={setEmail} />}
      {step === "loading" && <Loading />}
      {step === "tests" && (
        <ExecuteTests
          context={context}
          onFinish={onFinish}
          infras={infras.current}
          embed={isEmbed}
        />
      )}
      {step === "summary" && <Summary {...summary} />}
    </div>
  );
}

export default App;
